import localeSv from 'dayjs/locale/sv'
import localeEn from 'dayjs/locale/en'
import localeNb from 'dayjs/locale/nb'
import localePl from 'dayjs/locale/pl'
import localeZhCn from 'dayjs/locale/zh-cn'

export const LANGS_DATE_FORMATS = {
  sv: localeSv,
  en: localeEn,
  nb: localeNb,
  pl: localePl,
  'zh-cn': localeZhCn,
  zz: localeSv
}

/**
 * Dayjs date formats
 * @see https://day.js.org/docs/en/display/format
 */
export const LANGS_DATE_CUSTOM_FORMATS = {
  short: {
    nb: 'DDMMYYYY',
    sv: 'YYYYMMDD'
  },
  yearFirstFull: {
    nb: 'YYYYMMDD',
    sv: 'YYYYMMDD'
  },
  fullTime: 'HH:mm',
  fullMonthYear: 'MMMM YYYY',
  fullDate: 'dddd D MMMM YYYY',
  dateShortMonth: 'D MMM',
  customDateMonth: 'MMM',
  customDateDay: 'D',
  dateLongMonth: 'D MMMM',
  weekdayShort: 'ddd',
  weekday: 'dddd',
  dateWithShortWeekday: 'YYYY-MM-DD ddd'
}

export const DATEPICKER_DATE_FORMAT = 'M/D/YYYY'

export const DATEPICKER_ALT_DATE_FORMAT = 'YYYY-MM-DD'
