import { LANGS_DATE_FORMATS, LANGS_DATE_CUSTOM_FORMATS } from '@/constants/date'

const SERVER_DATE_FORMAT = 'YYYY-MM-DD'

export const useDate = () => {
  const { public: { locale } } = useRuntimeConfig()
  const lang = localeToLang(locale)

  const localeDateFormats = LANGS_DATE_FORMATS[lang].formats

  const localeDateFormat = (date, parseFormat, outputFormat) => {
    const dayjs = useDayjs()
    const f = parseFormat || SERVER_DATE_FORMAT
    return dayjs(date, f).format(outputFormat || localeDateFormats.L)
  }

  const localeDateCustomFormats = computed(
    () => Object.entries(LANGS_DATE_CUSTOM_FORMATS)
      .reduce((obj, [name, format]) => ({
        ...obj,
        [name]: typeof format === 'object' ? format[lang] : format
      }), {}))

  const localeDate = (date, format) => {
    const dayjs = useDayjs()
    return dayjs(date).format(format || localeDateFormats.L)
  }

  return {
    localeDateFormat,
    localeDateFormats,
    localeDateCustomFormats,
    localeDate,
  }
}
